import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from './../../environments/environment';
import { DataApi } from '../Model/dataApi';
import { param, when } from 'jquery';

@Injectable({
  providedIn: 'root'
})
export class DataApiService {

  constructor(private http: HttpClient) { }

  GetDataApi(url: string, params: any, module?: number) {
    return this.http.get<DataApi>(this.urlModule(module) + url+  params);
  };

  GetDataApiAsync(url: string, params: any, module?: number) {
    
    const options = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', }),
      body: params
    };
    //    return this.http.get<DataApi>(environment.apiUrl + url, options);
    return this.http.get<DataApi>(this.urlModule(module) + url + params).toPromise();
  };

  GetDataApiDash(url: string) {

    return this.http.get<DataApi>(url);
  };
  /*   return this.http.get<DataApi>(this.urlModule(module) + url + params);
  } */

  PostDataApi(url: string, params: any,module?: number) {
    return this.http.post<DataApi>(this.urlModule(module) + url, params);
  };

  PostDataApi1(url: string, params: any,module?: number) {
    return this.http.post<DataApi>( url, params);
  };

  DeleteDataApi(url: string, params: any,module?: number) {
    const options = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', }),
      body: params
    };
    return this.http.delete<DataApi>(this.urlModule(module) + url, options);
  }

  DeleteDataApiUrl(url: string, params: any,module?: number) {
    
    return this.http.delete<DataApi>(this.urlModule(module) + url + params);
  }

  PutDataApi(url: string, params: any,module?: number) {
    const options = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', }),
      body: params
    };
    return this.http.put<DataApi>(this.urlModule(module) + url, params);
  }

  PostAWS(url: string, params: any,module?: number) {
    const options = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', }),
      body: params
    };
    return this.http.post<DataApi>(environment.apiUrlSecurityAWS + url, params);
  }

  urlModule(module: number): string {
    var text = environment.apiDivulgacion;
    switch (module) {
      case 1: {
        //statements; 
        text = environment.apiDivulgacion;
        break;
      }

    }
    return text;
  }


}
